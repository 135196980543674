<template>
  <div class="messageBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">
        消息中心
        <img @click="touchEvent()" src="../../../image/messImg/sao.png" alt />
      </div>
      <div class="topRight" @click="editEvent()" v-if="!flag">编辑</div>
      <div class="topRight1" v-else @click="flag = false">取消</div>
    </div>
    <div class="containerBox">

      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          offset="800"
          @load="onLoad"
        >
      <div class="container" v-for="(item, index) in messList" :key="item.id">
        <van-checkbox
          v-if="flag"
          v-model="item.checked"
          @click="checkEvent(item, index)"
        ></van-checkbox>
        <van-swipe-cell>
          <div class="content" @click="detailEvent(item)">
            <div class="contentLeft">
              <img
                src="../../../image/messImg/ssage.png"
                v-if="item.type === 1"
                alt
              />
              <img
                src="../../../image/messImg/ssage1.png"
                v-if="item.type === 0"
                alt
              />
              <van-badge dot v-if="item.read === 0">
                <div class="child" />
              </van-badge>
            </div>

            <div class="contentRight">
              <div class="contentTitleTime">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.createTime }}</div>
              </div>
              <div class="contentAbstract">{{ item.data }}</div>
            </div>
          </div>
          <template #right>
            <van-button
              @click="delBtnEvent(item)"
              square
              text="删除"
              type="danger"
              class="delete-button"
            />
          </template>
        </van-swipe-cell>
        <!-- </van-checkbox> -->
      </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="btnBox" v-if="flag">
      <div @click="delNull()">一键清空</div>
      <div @click="delEvent()">删除({{ this.delData.ids.length }})</div>
    </div>
    <!-- <van-popup v-model="showDel" :close-on-click-overlay="false">
      <div class="delBox"></div>
    </van-popup>-->
  </div>
</template>

<script>
import {
  messagePage,
  messageDel,
  delBatch,
  messageRead,
} from "../../../api/lookAt";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      flag: false,
      // checked: false,
      showDel: false,
      messList: [],
      delData: {
        ids: [],
      },
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      isLoading: false,
      loading: false, //上拉加载
      finished: false, //加载与不加载的状态
    };
  },
  mounted() {
    this.getmessagePage();
  },
  methods: {
    getmessagePage() {
      messagePage(this.page).then((res) => {
        console.log(res, "消息");
        this.messList = this.messList.concat(res.data.data.items);
        res.data.data.items.forEach((item) => {
          item.checked = false;
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    editEvent() {
      this.flag = true;
    },
    // 多个删除
    delEvent() {
      Dialog.confirm({
        title: "删除",
        message: "确定要删除当前信息吗？",
      })
        .then(() => {
          // on confirm
          delBatch(this.delData).then((res) => {
            console.log(res, "多个删除");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 一键清空
    delNull() {
      let obj = {
        ids: [],
      };
      this.messList.forEach((i) => {
        console.log(i);
        obj.ids.push(i.id);
      });
      console.log(obj.ids);

      Dialog.confirm({
        title: "清空",
        message: "确定要清空吗？",
      })
        .then(() => {
          // on confirm
          delBatch(obj).then((res) => {
            console.log(res, "清空");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 单个删除
    delBtnEvent(item) {
      let obj = {
        id: "",
      };
      obj.id = item.id;
      // console.log(item);
      Dialog.confirm({
        title: "删除",
        message: "确定要删除当前信息吗？",
      })
        .then(() => {
          messageDel({ obj }).then((res) => {
            console.log(res, "删除消息");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    checkEvent(item, index) {
      console.log(item);
      // item.checked = !item.checked;
      this.delData.ids = [];
      this.messList[index].checked != item.checked;
      this.messList.forEach((i) => {
        if (i.checked) {
          this.delData.ids.push(i.id);
        }
      });
      console.log(this.delData.ids);
      this.$forceUpdate();
    },
    // 一键已读
    touchEvent() {
      let data = {
        ids: [],
      };
      console.log(11);
      this.messList.forEach((item) => {
        data.ids.push(item.id);
      });
      messageRead(data).then((res) => {
        console.log(res);
        this.getmessagePage();
      });
      console.log(data.ids);
    },
    detailEvent(item) {
      let data = {
        ids: [item.id],
      };
      messageRead(data).then((res) => {
      });
      this.$router.push({ path: "/messageDetail", query: { id: item.id } });
    },
    // 上拉刷新
    onRefresh() {
      this.page.pageNum = 1;
      this.messList = [];
      this.getmessagePage();
    },
    // 下拉刷新
    onLoad() {
      this.page.pageNum ++;
      this.getmessagePage();
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0px;
  background: #ffffff;
  z-index: 999;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  width: 370px;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topCon img {
  width: 48px;
  height: 48px;
}
.topRight,
.topRight1 {
  width: 248px;
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  text-align: right;
}
.topRight1 {
  color: #5777ed;
}
.goods-card {
  margin: 0;
  background-color: @white;
}
.delete-button {
  height: 100%;
}
.containerBox {
  margin-bottom: 150px;
  margin-top: 150px;
}
.container {
  width: 100%;
  display: flex;
  /* align-items: center; */
}
.content {
  margin-bottom: 50px;
  display: flex;
}
.contentLeft {
  width: 104px;
  height: 104px;
  /* background: pink; */

  margin-left: 32px;
}
.contentLeft img {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}
.contentRight {
  /* width: 100%; */
  margin-left: 32px;
}
.contentTitleTime {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.title {
  width: 400px;
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.time {
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
.contentAbstract {
  width: 550px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 8px;
}
/deep/.van-badge--fixed {
  position: absolute;
  top: -80px;
  right: 10px;
}
/deep/.van-swipe-cell {
  width: 100%;
}
.van-checkbox {
  width: 50px;
  height: 100px;
  margin-left: 30px;
  margin-right: 10px;
}
.btnBox {
  width: 750px;
  height: 150px;
  display: flex;
  position: fixed;
  bottom: 0px;
  background: #fff;
}
.btnBox div:nth-child(1) {
  width: 274px;
  height: 90px;
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #fa2819;
  font-size: 28px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
.btnBox div:nth-child(2) {
  width: 380px;
  height: 90px;
  background: #fa2819;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
.delBox {
  width: 622px;
}
</style>
